<template>
  <div class="fixed-phone" v-if="device !== 'desktop'">
    <a :href="`tel:${phoneNumber}`"><img :src="phoneImage" class="img-call" alt="phone"/></a>
    <!-- <b-dropdown variant="primary" size="sm" ref="dropdown" no-caret>
      <template #button-content>
        <img :src="phoneImage" class="img-call" alt="phone"/>
      </template>
      <b-dropdown-item :href="`tel:${phoneNumber}`">
          <div class="px-2 py-1">
            <b-button class="w-100" :href="`tel:${phoneNumber}`" size="sm" variant="primary">{{ phoneNumber }}: {{ $t("top-header.vacation-in-israel") }}</b-button>
          </div>
      </b-dropdown-item>
      <b-dropdown-item :href="`tel:${whiteLabel.vacationPhoneNumber}`">
          <div class="px-2 py-1">
            <b-button class="w-100" :href="`tel:${whiteLabel.vacationPhoneNumber}`" size="sm" variant="primary">{{ whiteLabel.vacationPhoneNumber }}: {{ $t("top-header.vacation-abroad") }}</b-button>
          </div>
      </b-dropdown-item>
    </b-dropdown> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import {
//   BButton,
//   BDropdown,
//   BDropdownItem,
// } from 'bootstrap-vue';
import agencyMarketer from '../../mixins/agencyMarketer';

export default {
  name: 'BottomLeftCall',
  components: {
    // BButton,
    // BDropdown,
    // BDropdownItem,
  },
  mixins: [agencyMarketer],
  computed: {
    ...mapGetters({
      // lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      whiteLabel: 'GET_WHITE_LABEL_DATA',
      // isLanding: 'GET_IS_LANDING_PAGE',
      // isLandingChain: 'GET_IS_LANDING_PAGE_CHAIN',
      // landingInfo: 'GET_LANDING_INFO',
      // destinationList: 'GET_DOMESTIC_DESTINATION',
      // agencyUser: 'GET_AGENCY_USER',
      // odysseyAgentState: 'GET_ODYSSEY_AGENT_STATE',
    }),
    phoneImage() {
      const root = this.$route.name;
      let path = '';
      if (root !== '') {
        path = '../';
      }
      return `${path}assets/img/phone-call.png`;
    },
  },
  watch: {
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>
<style scoped>
.fixed-phone{
    position: fixed;
    bottom: 2px;
    left: 2px;
    z-index: 100;
    display: flex;
    flex-direction: row-reverse;
    transform: translateZ(0);
    will-change: transform;
}
.img-call{
  width: 50px;
}

@media (max-width: 767px) {

}
</style>

<style>
.fixed-phone ul.dropdown-menu, .fixed-phone a.dropdown-item {
  padding: 0;
}
.fixed-phone button.dropdown-toggle.btn-primary,
.fixed-phone button.dropdown-toggle.btn-primary:active,
.fixed-phone button.dropdown-toggle.btn-primary:focus{
  background: none;
  border: none;
}
.fixed-phone button.dropdown-toggle.btn-primary{
  opacity: 1;
}
.fixed-phone button.dropdown-toggle.btn-primary:hover{
  opacity: 1;
}
</style>
